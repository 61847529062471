export const releaseFlags = {
  post_mmp: false,
  stores: true,
  feedBack: false,
  tbd: false,
  qwik: false,
  websitesMMP: false,
  custom_domains: false,
  custom_domains_sites: true,
  custom_domains_stores: false,
  inspire_seo_sites: true,
  inspire_seo_stores: true,
  inspire_search: true,
  inspire_presentation_description: true,
  custom_products: false,
  orders_table_event_type_filter: false,
  aggregated_po_margin: false,
  website_payment_methods: false,
  website_account_management: false,
  vs_migration: true,
  vs_text_editor: false,
  vs_retouch_tool: false,
  company_enrichments: true,
  web_store_project_invoices: false,
  sending_email_from: false,
  confetti: true,
  super_user: true,
  crm_email_page: true,
  crm_global_notes_page: false,
  crm_global_tasks_page: true,
  crm_prospects: true,
  settings_third_party_system_name: true,
  settings_create_roles: false,
  settings_orders: true,
  crm_contacts_prospect: true,
  store_design_text_module: true,
  store_product_source_type: true,
  store_fundraising: true,
  presentation_product_additional_options: true,
  permissions_create_po: false,
  permissions_create_invoice: true,
  notification_project_deleted: false,
  presentation_pdp_more_info_from_velocity_poc: false,
  store_pdp_more_info_from_velocity_poc: false,
  pay_with_points: false,
  presentations_global_price_adjustments_preview_popover: false,
  percentage_profit_rate_warning: false,
};
