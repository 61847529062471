import { importProvidersFrom } from '@angular/core';

import { CosDialogModule } from '@cosmos/components/dialog';
import { CosFormFieldModule } from '@cosmos/components/form-field';
import { CosMenuModule } from '@cosmos/components/menu';
import { AppSettingsModule } from '@cosmos/data-access-app-settings';
import { provideCosmosLayout } from '@cosmos/layout';
import { provideMeta, type MetaSettings } from '@cosmos/meta';
import { provideNgEventPlugins } from '@cosmos/ng-event-plugins';
import { provideQuillConfig } from '@cosmos/quill-config';
import { CosmosScrollPositionRestorationModule } from '@cosmos/scroll-position-restoration';
import { LAYOUT_CONFIG } from '@cosmos/types-layout';
import { COS_UI_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@cosmos/ui-autocomplete';
import { COS_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@cosmos/ui-datepicker';
import { CosNotificationModule } from '@cosmos/ui-notification';
import { COS_SELECT_SCROLL_STRATEGY_PROVIDER } from '@cosmos/ui-select';
import { COS_DATE_LOCALE } from '@cosmos/util-datetime';
import { provideWebDownload } from '@cosmos/util-download';
import { provideAsyncTippyLoader } from '@cosmos/util-tippy-config';
import {
  DESKTOP_HEADER_NAV_HEIGHT,
  MOBILE_HEADER_NAV_HEIGHT,
} from '@esp/layouts/ui-encore-layout';

const COSMOS_META_CONFIG: MetaSettings = {
  applicationName: 'ESP+',
  pageTitleSeparator: ' - ',
  defaults: {
    title: 'ESP+',
  },
};

export const provideAppCosmos = () => [
  provideAsyncTippyLoader(),
  provideNgEventPlugins(),
  provideCosmosLayout(),
  importProvidersFrom([
    CosDialogModule,
    CosMenuModule,
    CosFormFieldModule.forRoot(),
    CosmosScrollPositionRestorationModule,
    AppSettingsModule,
    CosNotificationModule,
  ]),
  provideQuillConfig(),
  provideWebDownload(),
  provideMeta(COSMOS_META_CONFIG),
  { provide: COS_DATE_LOCALE, useValue: 'en-US' },
  COS_SELECT_SCROLL_STRATEGY_PROVIDER,
  COS_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
  COS_UI_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
  {
    provide: LAYOUT_CONFIG,
    useValue: {
      desktopHeaderNavHeight: DESKTOP_HEADER_NAV_HEIGHT,
      mobileHeaderNavHeight: MOBILE_HEADER_NAV_HEIGHT,
    },
  },
];
