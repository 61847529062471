import {
  WhiteLabelActions,
  WhiteLabelQueries,
} from 'esp/white-label/data-access';

import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { first, tap } from 'rxjs';

import { WhiteLabelService } from '../services/white-label.service';

export const whiteLabelResolver = () => {
  const service = inject(WhiteLabelService);
  const store = inject(Store);

  return store.dispatch(WhiteLabelActions.LoadSettings).pipe(
    tap(() => {
      const whiteLabelSettings$ = store
        .select(WhiteLabelQueries.getSettings)
        .pipe(first(Boolean));

      whiteLabelSettings$.subscribe((whiteLabelSettings) => {
        service.applyStyleAttributes(whiteLabelSettings);
        service.applyWhiteLabelStyles(whiteLabelSettings);
        service.applyWhiteLabelFavicon(whiteLabelSettings);
      });
    })
  );
};
