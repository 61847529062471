import { CapabilitiesEnum } from '@cosmos/types-common';
import type { CosGlobalHeaderNavigationItem } from '@cosmos/types-layout';
import { LanguageScope } from '@cosmos/util-translations';
import {
  allowedHeaderMenuPropertiesToOverride,
  type HeaderMenuItemOverrideSettings,
  type HeaderMenuItemsKeyValue,
  type HeaderMenuTypes,
} from '@esp/misc/types-menu-items';

import { constructMenu, safeOverrideProperties } from './utils';

const feedbackMenuItem = (
  overrides?: Partial<HeaderMenuItemOverrideSettings>
): CosGlobalHeaderNavigationItem => {
  return {
    id: 'feedback',
    title: 'cosmos.components.global-header.feedback',
    classes: '!hidden xl:!flex',
    hideTitle: false,
    languageScope: LanguageScope.Cosmos,
    url: ['mailto:feedback@asicentral.com?subject=ESP+%20Feedback'],
    externalUrl: true,
    featureFlags: { matches: ['feedBack'] },
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedHeaderMenuPropertiesToOverride,
    }),
  };
};

const collectionsMenuItem = (
  overrides?: Partial<HeaderMenuItemOverrideSettings>
): CosGlobalHeaderNavigationItem => {
  return {
    id: 'collections',
    title: 'cosmos.components.global-header.collections',
    classes: '!hidden xl:!flex',
    hideTitle: false,
    languageScope: LanguageScope.Cosmos,
    menuFactory: () =>
      // eslint-disable-next-line @nx/enforce-module-boundaries
      import(
        /* webpackPrefetch: true */
        /* webpackChunkName: 'esp-collections-feature-collections-menu' */ '@esp/collections/feature-collections-menu'
      ).then((m) => m.CollectionsMenuComponent),
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedHeaderMenuPropertiesToOverride,
    }),
  };
};

const projectsMenuItem = (
  overrides?: Partial<HeaderMenuItemOverrideSettings>
): CosGlobalHeaderNavigationItem => {
  return {
    id: 'projects',
    title: 'cosmos.components.global-header.projects',
    classes: '!hidden xl:!flex',
    hideTitle: false,
    languageScope: LanguageScope.Cosmos,
    menuFactory: () =>
      // eslint-disable-next-line @nx/enforce-module-boundaries
      import(
        /* webpackPrefetch: true */
        /* webpackChunkName: 'esp-projects-feature-projects-menu' */ '@esp/projects/feature-projects-menu'
      ).then((m) => m.ProjectsMenuComponent as unknown as any),
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedHeaderMenuPropertiesToOverride,
    }),
  };
};

const crmMenuItem = (
  overrides?: Partial<HeaderMenuItemOverrideSettings>
): CosGlobalHeaderNavigationItem => {
  return {
    id: 'crm',
    title: 'cosmos.components.global-header.crm',
    classes: '!hidden xl:!flex',
    hideTitle: false,
    languageScope: LanguageScope.Cosmos,
    menuFactory: () =>
      // eslint-disable-next-line @nx/enforce-module-boundaries
      import(
        /* webpackPrefetch: true */
        /* webpackChunkName: 'esp-crm-ui-feature-crm-menu' */ '@esp/crm/feature-crm-menu'
      ).then(({ CrmMenuComponent }) => CrmMenuComponent),
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedHeaderMenuPropertiesToOverride,
    }),
  };
};

const websitesMenuItem = (
  overrides?: Partial<HeaderMenuItemOverrideSettings>
): CosGlobalHeaderNavigationItem => {
  return {
    id: 'websites',
    title: 'cosmos.components.global-header.websites',
    classes: '!hidden xl:!flex',
    hideTitle: false,
    languageScope: LanguageScope.Cosmos,
    url: ['/websites'],
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedHeaderMenuPropertiesToOverride,
    }),
  };
};

const storesMenuItem = (
  overrides?: Partial<HeaderMenuItemOverrideSettings>
): CosGlobalHeaderNavigationItem => {
  return {
    id: 'stores',
    title: 'cosmos.components.global-header.stores',
    classes: '!hidden xl:!flex',
    hideTitle: false,
    languageScope: LanguageScope.Cosmos,
    url: ['/stores'],
    featureFlags: { matches: ['stores'] },
    capabilities: [CapabilitiesEnum.EspPlusStores],
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedHeaderMenuPropertiesToOverride,
    }),
  };
};

const helpCenterMenuItem = (
  overrides?: Partial<HeaderMenuItemOverrideSettings>
): CosGlobalHeaderNavigationItem => {
  return {
    id: 'helpcenter',
    title: 'cosmos.components.global-header.help-center',
    classes: '!hidden xl:!flex',
    icon: 'fa fa-solid fa-circle-question',
    hideTitle: true,
    languageScope: LanguageScope.Cosmos,
    menuFactory: () =>
      // eslint-disable-next-line @nx/enforce-module-boundaries
      import('@esp/misc/feature-help-center').then(
        (m) => m.HelpCenterMenuComponent
      ),
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedHeaderMenuPropertiesToOverride,
    }),
  };
};

const externalResourceMenuItem = (
  overrides?: Partial<HeaderMenuItemOverrideSettings>
): CosGlobalHeaderNavigationItem => {
  return {
    id: 'external-resource',
    title: 'cosmos.components.global-header.community',
    icon: 'fa fa-people-group',
    classes: '!hidden xl:!flex',
    hideTitle: true,
    languageScope: LanguageScope.Cosmos,
    menuFactory: () =>
      // eslint-disable-next-line @nx/enforce-module-boundaries
      import('@esp/misc/feature-external-resource-menu').then(
        (m) => m.ExternalResourceMenuComponent as unknown as any
      ),
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedHeaderMenuPropertiesToOverride,
    }),
  };
};

const accountsMenuItem = (
  overrides?: Partial<HeaderMenuItemOverrideSettings>
): CosGlobalHeaderNavigationItem => {
  return {
    id: 'account',
    title: 'cosmos.components.global-header.account',
    icon: 'fa fa-user-circle',
    hideTitle: true,
    languageScope: LanguageScope.Cosmos,
    classes: '!hidden xl:!flex',
    menuFactory: () =>
      // eslint-disable-next-line @nx/enforce-module-boundaries
      import('@esp/common/feature-user-menu').then(
        ({ AsiUserMenuComponent }) => AsiUserMenuComponent
      ),
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedHeaderMenuPropertiesToOverride,
    }),
  };
};

const notificationsMenuItem = (
  overrides?: Partial<HeaderMenuItemOverrideSettings>
): CosGlobalHeaderNavigationItem => {
  return {
    id: 'notifications',
    title: 'cosmos.components.global-header.notifications',
    icon: 'fa fa-bell',
    hideTitle: true,
    languageScope: LanguageScope.Cosmos,
    classes: '!hidden xl:!flex',
    actionOnClick: () =>
      // eslint-disable-next-line @nx/enforce-module-boundaries
      import('@esp/notifications/data-access-notifications').then(
        ({ MarkAllAsReadService }) => MarkAllAsReadService
      ),
    menuFactory: () =>
      // eslint-disable-next-line @nx/enforce-module-boundaries
      import('@esp/notifications/feature-notifications').then(
        ({ NotificationsMenuComponent }) => NotificationsMenuComponent
      ),
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedHeaderMenuPropertiesToOverride,
    }),
  };
};

const presentationsMenuItem = (
  overrides?: Partial<HeaderMenuItemOverrideSettings>
): CosGlobalHeaderNavigationItem => {
  return {
    id: 'presentations',
    title: 'cosmos.components.global-header.presentations',
    classes: '!hidden xl:!flex',
    hideTitle: false,
    languageScope: LanguageScope.Cosmos,
    url: ['/presentations/search'],
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedHeaderMenuPropertiesToOverride,
    }),
  };
};

const quoteOrdersMenuItem = (
  overrides?: Partial<HeaderMenuItemOverrideSettings>
): CosGlobalHeaderNavigationItem => {
  return {
    id: 'quote-orders',
    title: 'cosmos.components.global-header.quotes-orders',
    classes: '!hidden xl:!flex',
    hideTitle: false,
    openInNewTab: true,
    externalUrl: true,
    languageScope: LanguageScope.Cosmos,
    url: ['#'], // It needs to be ovewritten!
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedHeaderMenuPropertiesToOverride,
    }),
  };
};

const mySiteMenuItem = (
  overrides?: Partial<HeaderMenuItemOverrideSettings>
): CosGlobalHeaderNavigationItem => {
  return {
    id: 'my-site',
    title: 'cosmos.components.global-header.my-site',
    classes: '!hidden xl:!flex',
    hideTitle: false,
    openInNewTab: true,
    externalUrl: true,
    languageScope: LanguageScope.Cosmos,
    url: ['#'], // It needs to be ovewritten!
    ...safeOverrideProperties({
      overrides,
      allowedPropertiesToOverride: allowedHeaderMenuPropertiesToOverride,
    }),
  };
};

export const availableHeaderMenuItems: HeaderMenuItemsKeyValue = {
  feedback: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    feedbackMenuItem(overrides),
  collections: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    collectionsMenuItem(overrides),
  projects: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    projectsMenuItem(overrides),
  crm: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    crmMenuItem(overrides),
  websites: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    websitesMenuItem(overrides),
  stores: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    storesMenuItem(overrides),
  helpCenter: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    helpCenterMenuItem(overrides),
  notificationsItem: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    notificationsMenuItem(overrides),
  accounts: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    accountsMenuItem(overrides),
  presentations: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    presentationsMenuItem(overrides),
  quoteOrders: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    quoteOrdersMenuItem(overrides),
  mySite: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    mySiteMenuItem(overrides),
  externalResource: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    externalResourceMenuItem(overrides),
};

export const constructHeaderMenu = (menuItemsConfig: HeaderMenuTypes[]) =>
  constructMenu({
    menuItemsConfig,
    availableMenuItems: availableHeaderMenuItems,
  });
