import { provideStates } from '@ngxs/store';

import { AlgoliaTasksInterceptor } from '@esp/search/data-access-algolia-tasks';

import { PreferredSupplierActions } from './actions';
import {
  PreferredSupplierListCompaniesState,
  PreferredSupplierListsState,
  PreferredSupplierListWebsitesState,
  PreferredSupplierMergedListState,
  PreferredSupplierState,
} from './states';

export const providePreferredSupplierDataAccess = () => {
  return [
    provideStates([
      PreferredSupplierState,
      PreferredSupplierListsState,
      PreferredSupplierMergedListState,
      PreferredSupplierListWebsitesState,
      PreferredSupplierListCompaniesState,
    ]),
  ];
};

export function providePreferredSupplierDataAccessInterceptor() {
  return [
    AlgoliaTasksInterceptor.create({
      urlPattern: /(semaphore\/suppliers)|(semaphore\/lists)/,
      ActionToDispatch: PreferredSupplierActions.SearchIndexOperationComplete,
    }),
  ];
}
