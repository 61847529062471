@if (vm$ | async; as vm) {
  <esp-encore-layout
    [showHeader]="!vm.routerData.hideGlobalHeader"
    [showFooter]="!vm.routerData.hideGlobalFooter"
  >
    @if (vm.renderHeaderAndFooter) {
      <ng-container ngProjectAs="cos-global-header">
        <esp-feature-global-header
          [showGlobalSearch]="!vm.routerData.hideGlobalSearch"
        />
      </ng-container>
    }
    @if (vm.renderHeaderAndFooter || vm.routerData.forceGlobalFooter) {
      <esp-feature-global-footer />
    }
  </esp-encore-layout>
}
