import { InjectionToken } from '@angular/core';

export interface LayoutConfig {
  desktopHeaderNavHeight: number;
  mobileHeaderNavHeight: number;
}

export const LAYOUT_CONFIG = new InjectionToken<LayoutConfig>(
  ngDevMode ? 'LAYOUT_CONFIG' : ''
);
