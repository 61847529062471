import { A11yModule } from '@angular/cdk/a11y';
import { AsyncPipe } from '@angular/common';
import { Component, inject, Injector, NgZone } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { NAVIGATOR, WINDOW } from '@ng-web-apis/common';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  type Observable,
} from 'rxjs';

import { startAppCues } from '@cosmos/analytics/appcues';
import { CosAnalyticsService } from '@cosmos/analytics/common';
import { startHeapIo } from '@cosmos/analytics/heap-io';
import type { DefaultRouteData } from '@cosmos/router';
import { RolesEnum } from '@cosmos/types-common';
import { AuthFacade, HasRolePipe } from '@esp/auth/data-access-auth';
import { EncoreLayoutComponent } from '@esp/layouts/ui-encore-layout';
import { FeatureGlobalFooterComponent } from '@esp/misc/feature-global-footer';
import { FeatureGlobalHeaderComponent } from '@esp/misc/feature-global-header';
import { SwUpdateService } from '@esp/notifications/feature-notifications-refresh';
import { RouterFacade } from '@esp/router';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'esp-app-root',
  templateUrl: './app-root.component.html',
  styleUrls: ['./app-root.component.scss'],
  standalone: true,
  imports: [
    A11yModule,
    EncoreLayoutComponent,
    FeatureGlobalHeaderComponent,
    FeatureGlobalFooterComponent,
    AsyncPipe,
  ],
})
export class AppRootComponent {
  /**
   * This is used to prevent content jumping. The router data emits asynchronously, which
   * means the `esp-global-search` might be rendered and removed immediately and cause flickering.
   */
  private readonly _routerData$ = this._router.events.pipe(
    // This is used to wait for the very first navigation to finish before starting listening
    // for router data events. Suppose the user is logged in and navigates to `/` page. We've got a guard
    // that will navigate the user to the `/home` page. This means the `esp-global-search` will show up
    // on the `/` page and will be removed on the `/home` page.
    first((event) => event instanceof NavigationEnd),
    mergeMap(() => this._routerFacade.data$ as Observable<DefaultRouteData>),
    // Wait until the router data is available.
    filter(Boolean)
  );

  readonly vm$ = combineLatest({
    routerData: this._routerData$,
    renderHeaderAndFooter: this._authFacade.loggedIn$.pipe(
      // We don't want to show global header once user is logged in and before
      // redirection to the home page cause in this case we start showing the global header
      // right on the login page.
      // More over we can see the header search bar for some short moment until a redirection
      // to the home page is finished.
      // To avoid this we should wait until the redirection process is finished
      // and after this to show the header only.
      switchMap((loggedIn) => {
        if (loggedIn) {
          return this._router.events.pipe(
            first((event) => event instanceof NavigationEnd),
            map(() => loggedIn)
          );
        }
        return of(loggedIn);
      })
    ),
  });

  constructor(
    private readonly _ngZone: NgZone,
    private readonly _router: Router,
    private readonly _routerFacade: RouterFacade,
    private readonly _authFacade: AuthFacade,
    private readonly _analytics: CosAnalyticsService,
    private readonly _swUpdate: SwUpdateService
  ) {
    this._ensureValidProductionDomain();
    this._setupAnalytics();
    this._swUpdate.waitForUpdate();
  }

  navigateByUrl(url: string): void {
    // Caretaker note: this method is used for e2e tests
    this._ngZone.run(() => {
      this._router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this._router.navigateByUrl(url));
    });
  }

  private _setupAnalytics(): void {
    const injector = inject(Injector);
    const hasRolePipe = inject(HasRolePipe);
    const user$ = this._authFacade.getUser();

    // We should wait until the user is logged in before loading Heap.
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    user$.pipe(take(1)).subscribe(() => startHeapIo(injector));

    startAppCues({
      injector,
      identifyConfigTransformer: (config) => {
        config.PreferredSupplierAdmin = hasRolePipe.transform(
          RolesEnum.PVAdmin
        );
        return config;
      },
    });

    user$
      .pipe(
        distinctUntilChanged((a, b) => a?.Id === b?.Id),
        takeUntilDestroyed()
      )
      .subscribe(
        ({
          Id,
          Name,
          Email,
          GivenName,
          FamilyName,
          TenantCode,
          TenantId,
          AsiNumber,
          CompanyId,
          CompanyName,
          IsAdmin,
          IsDistributor,
          IsSupplier,
          IsCorporate,
          IsInternal,
          displayName,
          UserName,
        }) => {
          this._analytics.userEvent$.next({
            userId: Id.toString(),
            traits: {
              Name,
              Email,
              GivenName,
              FamilyName,
              TenantCode,
              TenantId,
              AsiNumber,
              CompanyId,
              CompanyName,
              IsAdmin,
              IsDistributor,
              IsSupplier,
              IsCorporate,
              IsInternal,
              displayName,
              UserName,
            },
          });
        }
      );
  }

  private async _ensureValidProductionDomain() {
    if ((ngDevMode && typeof jest !== 'undefined') || global_isServeMode)
      return;

    const { location } = inject(WINDOW);
    const currentDomain = location.host;

    // See `on-pr-or-push.yml`.
    const previewSubdomain = 'delightful-pebble';

    // We don't need to redirect to the ESP+ domain
    // because we're on a preview domain.
    if (currentDomain.includes(previewSubdomain)) return;

    const espPlusDomain = process.env.ESP_PLUS_DOMAIN;
    if (espPlusDomain && currentDomain !== espPlusDomain) {
      const navigator = inject(NAVIGATOR);

      try {
        // We need to wait until the service worker is unregistered
        // because the browser may initiate navigation to the new host
        // before the worker is unregistered. This could occur if the
        // ESP+ domain has already been resolved and cached previously.
        const registrations = await navigator.serviceWorker.getRegistrations();
        await Promise.all(
          registrations.map((registration) => registration.unregister())
        );
      } finally {
        location.host = espPlusDomain;
      }
    }
  }
}
